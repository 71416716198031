body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
  margin: 0;
  padding: 0;
}

.app {
  text-align: center;
  padding: 20px;
}

h1 {
  color: #007bff;
  font-size: 2rem;
  margin-bottom: 20px;
}

input {
  padding: 10px;
  font-size: 1rem;
  width: 80%;
  max-width: 500px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.play-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.play-button:hover {
  background-color: #0056b3;
}

.thumbnail-container {
  max-width: 800px;
  margin: 20px auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
}