.video-container {
  max-width: 800px;
  margin: 20px auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
}

.video-js {
  width: 100%;
  height: auto;
}
